<template>
	<div>
    <br/>
    <div v-for="(field, index) in fields" :key="index">
      <v-text-field
        v-if="!field.isCalendar&&!field.isCheckbox"
        rounded
        outlined
        :label="field.text"
        v-model="field.value"
        :rules="field.rule!=''?[fieldRules[field.rule]]:[]"
      />
      <v-checkbox v-else-if="field.isCheckbox" v-model="field.value" :label="field.text"/>
      <v-menu
        v-else
        v-model="field.showCalendar"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="field.value"
            :label="field.text"
            prepend-inner-icon="fa-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            rounded
            outlined
          />
        </template>
        <v-date-picker
          v-model="field.value"
          :first-day-of-week="1"
          @input="field.showCalendar = false"
        />
      </v-menu>
    </div>
    <v-btn v-if="rulesOk" :href="statUrl" target="_blank" rounded color="success">Statisztika készítése</v-btn>
	</div>
</template>

<script>



	export default {
    props: [],
		components: {

		},
		data: function(){
			return {
        fields: [
          {
            text: 'Statisztika kezdete',
            rule: "",
            required: true,
            isCalendar: true,
            showCalendar: false,
            value: "2021-09-01",
            name: 'start'
          },
          {
            text: 'Statisztika vége',
            rule: "",
            required: true,
            isCalendar: true,
            showCalendar: false,
            value: "2022-01-31",
            name: 'end'
          },
          {
            text: 'Kölyökatléta maximális életkora a statisztika kezdetén',
            rule: "isNumber",
            required: true,
            value: 9,
            name: 'maxAgeOfKidAthlete'
          },
          {
            text: 'Minimum versenyindulás kölyökatlétánként egyesületi versenyen',
            rule: "isNumber",
            required: true,
            value: 0,
            name: 'minimumClubCompetitionAttendancePerKA'
          },
          {
            text: 'Minimum jelenlét a foglalkozásokon kölyökatlétánként %-ban',
            rule: "isNumber",
            required: true,
            value: 0,
            name: 'minimumAttendancePerKA'
          },
          {
            text: 'Minimum foglalkozás egy csoportban',
            rule: "isNumber",
            required: true,
            value: 1,
            name: 'minimumTrainingPerGroup'
          },
          {
            text: 'Minimum kölyökatléta egy csoportban',
            rule: "isNumber",
            required: true,
            value: 15,
            name: 'minimumKidAthleteInAGroup'
          },
          {
            text: 'Minimum csoport egy egyesületben',
            rule: "isNumber",
            required: true,
            value: 0,
            name: 'minimumGroupInAClub'
          },
          {
            text: 'Minimum szervezendő egyesületi versenyek száma',
            rule: "isNumber",
            required: true,
            value: 0,
            name: 'minimumOrganizedClubCompetition'
          },
          {
            text: 'Csak az adott időszakban szerződött egyesületek',
            required: false,
            value: true,
            name: 'onlyContracted',
            isCheckbox: true,
            rule: ''
          }
        ]
      }
		},
		computed: {
      rulesOk: function() {
        for(var i = 0;i<this.fields.length;i++) if(this.fields[i].rule!=''&&this.fieldRules[this.fields[i].rule](this.fields[i].value)!==true) return false;
        if(Date.parse(this.fields.find(f => f.name=="start").value)>=Date.parse(this.fields.find(f => f.name=="end").value)) return false;
        return true;
      },
      statUrl: function() {
        const parameters = this.fields.map(f => `${f.name}=${f.value}`).join('&');
        return `${this.axios.defaults.baseURL}statistics/clubs/coordinatorXLSX?${parameters}`;
      }
		},
		methods: {

		},
		mounted(){

		}
	}
</script>
