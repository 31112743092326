<template>
	<div>
    <v-tabs icons-and-text>
      <v-tab v-if="$store.getters.userRole>=roles.regionalCoordinator">
        Egyesületek
        <v-icon>
          fa-map-marker
        </v-icon>
      </v-tab>
      <v-tab-item v-if="$store.getters.userRole>=roles.regionalCoordinator">
        <RCClub/>
      </v-tab-item>
    </v-tabs>


	</div>
</template>

<script>
	import router from '../router';
  import RCClub from '../components/statistics-RC-club'
  import roles from '../plugins/roles'
	export default {
    props: [],
		components: {
      RCClub
		},
		data: function(){
			return {
        roles
      }
		},
		computed: {

		},
		methods: {

		},
		mounted(){

		}
	}
</script>
